import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BankForm = _resolveComponent("BankForm")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, {
      columns: _ctx.tabelParams.columnsTable,
      endpoint: _ctx.tabelParams.endpoint,
      headerTitle: _ctx.tabelParams.headerTitle,
      buttonTitle: _ctx.tabelParams.buttonTitle,
      editTitle: _ctx.tabelParams.editTitle,
      showColumnActivate: true,
      confirmDialogDelete: _ctx.tabelParams.confirmDialogDelete
    }, {
      form: _withCtx(() => [
        _createVNode(_component_BankForm)
      ]),
      _: 1
    }, 8, ["columns", "endpoint", "headerTitle", "buttonTitle", "editTitle", "confirmDialogDelete"])
  ]))
}