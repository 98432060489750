import { ref, onMounted, reactive, defineComponent } from 'vue'
import DataTable from '@/views/components/table/HjpDataTable.vue'
import BankForm from '@/views/pages/master-setting/bank/Form.vue'
import {
  get, map
} from 'lodash'

export default defineComponent({
  components: {
    DataTable,
    BankForm
  },
  setup() {
    const tabelParams = reactive({
      endpoint: '/management/v1/MsBank',
      headerTitle: 'Master Bank',
      buttonTitle: 'Tambah Bank',
      editTitle: 'Edit',
      confirmDialogDelete: {
        title: 'Hapus Bank?',
        subTitle: 'Pastikan Bank yang Anda tambahkan sudah sesuai dan tidak ada perubahan ditengah-tengah.',
        btnSubmitTitle: 'Hapus Bank',
      },
      columnsTable: [
        {
          field: 'Name',
          headerName: 'Bank',
          searchable: true,
          fieldSearch: 'Name',
          isRender: false
        },
      ]
    })
    // const slotBank = 'MsBank.Name'
    // const slotChannelBank = 'MsChannelBanks.OaAccount'
    // const getBankChannelId = (data: any) => {
    //   console.log('data', data)
    // }
    return {
      tabelParams
    }
  }
})
